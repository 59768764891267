<template>
  <div class="col" dir="ltr">

    <card :title="$t('roles_title')">
      <card>
        <div class="row" style="display: flex; align-items: center">
          <div class="col-xl-4">
            <el-select
              v-model="role"
              filterable
             :placeholder="$t('select_Role')"
              style="width: 100%; direction: ltr"
            >
              <el-option
                v-for="(role, id) in $store.getters['roles/roles']"
                :key="id"
                :label="role.name"
                :value="role.id"
              >
                <span style="color: #8492a6; font-size: 13px">
                  <i class="el-icon-user-solid"></i>
                  {{ role.name }}
                </span>
              </el-option>
            </el-select>
          </div>
          <div class="col-xl-2">
            <el-input
              :placeholder="$t('Role_name')"
              v-model="selected_role.name"
              class="w-100 mt-1 mt-xl-0"
            ></el-input>
          </div>
          <div class="col-xl-2">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="role_dialog = true"
              class="w-100 text-center px-2 mt-1 mt-xl-0"
            >
              {{$t("Add_Role")}}
            </el-button>
          </div>
          <div class="col-xl-2">
            <el-button
              type="success"
              @click="update"
              icon="el-icon-folder-checked"
              class="w-100 text-center px-2 mt-1 mt-xl-0"
            >
              {{$t("Update_Role")}}
            </el-button>
          </div>
          <div class="col-xl-2">
            <el-button
              type="danger"
              @click="deleteRole"
              icon="el-icon-delete"
              class="w-100 text-center px-2 mt-1 mt-xl-0"
            >
               {{$t("Delete_Role")}}
            </el-button>
          </div>
        </div>
      </card>
      <div>
        <div class="row">
          <div
            class="col-lg-4"
            v-for="(group, key) in $store.getters['roles/permissionsGroups']"
            :key="key"
          >
            <el-card
              v-loading="$store.getters['roles/rolesLoad']"
              :header="key"
              class="mb-3"
            >
              <el-checkbox-group
                v-for="(permission, id) in group"
                :key="id"
                v-model="Permissions"
                style="text-align: initial"
              >
                <el-checkbox
                  :label="`${permission.id}`"
                  :key="permission.id"
                  style="width: 100%"
                  >{{ permission.name }}
                </el-checkbox>
              </el-checkbox-group>
            </el-card>
          </div>
        </div>
      </div>
    </card>
    <el-dialog :title="$t('Add_Role')" :visible.sync="role_dialog" top="4vh">
      <el-form :model="selected_role">
        <el-form-item :label="$t('Global.name')">
          <el-input v-model="selected_role.name" style="width: 100%"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="role_dialog = false">{{$t("cancel")}}</el-button>
        <el-button type="primary" @click="add">{{$t("save_Role")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "index",
  data: () => ({
    role_dialog: false,
    role: null,
    selected_role: {},
    Permissions: [],
  }),
  watch: {
    role(val) {
      this.$store.dispatch("roles/getRolePermissions", val).then((_) => {
        this.Permissions = this.$store.getters["roles/rolePermissions"];
        this.selected_role = this.$store.getters["roles/roles"].filter(
          (role) => role.id == val
        )[0];
      });
    },
  },
  mounted() {
    this.Refresh();
  },

  methods: {
    Refresh() {
      this.$store.dispatch("roles/getRoles");
    },
    update() {
      this.$store
        .dispatch("roles/updateRole", {
          id: this.role,
          data: {
            name: this.selected_role.name,
            permissions: this.Permissions,
          },
        })
        .then((_) => {
          Swal.fire({
            title: "Updated!",
            text: "Role has been updated.",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          this.Refresh();
        });
    },
    add() {
      this.$store.dispatch("roles/addRole", this.selected_role).then((_) => {
        Swal.fire({
          title: "Updated!",
          text: "Role has been Updated.",
          type: "success",
          confirmButtonClass: "btn btn-success btn-fill",
          buttonsStyling: false,
        });
        this.Refresh();
        this.role_dialog = false;
      });
    },
    deleteRole() {
      Swal.fire({
        title: "Are you sure?",
        text: `You will Delete this item`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "delete it!",
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("roles/deleteRole", this.role).then((_) => {
            this.Refresh(null);
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
